import React, { useEffect, useContext } from "react"
import { Context } from "../utils/Provider"
import { Title, Text, Box } from "../components/system"
import Layout from "../components/layout"

const Success = props => {
  const [dispatch] = useContext(Context)
  useEffect(() => {
    localStorage.removeItem("products")
    dispatch({ type: "SET_CART", payload: [] })
  }, [dispatch])
  return (
    <Layout dark>
      <Box py={7}>
        <Title>Order Confirmation</Title>
        <Text>
          You should recieve an email shortly, thank you for shopping with
          Ilana.
        </Text>
      </Box>
    </Layout>
  )
}

export default Success
